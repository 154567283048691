import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Box,
  Text,
  useToast,
  useBreakpointValue,
  HStack,
} from "@chakra-ui/react";
import { SubmitHandler } from "react-hook-form";
import BookDemoForm, { FormValues } from "./bookDemoForm";
import HeroSectionStyle from "../index.module.css";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const BookDemoModal = ({ isOpen, onClose }: any) => {
  const toast = useToast();
  const [submitResponse, setSubmitResponse] = useState(false);

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    // console.log(values);
    const googleFormURL =
      "https://docs.google.com/forms/d/e/1FAIpQLSemNRDQpCk_NnHsyXvL_4ffVz5V90jwzJjPY3q81mXeAiN7xQ/formResponse";

    const formSubmissionData = new URLSearchParams();
    formSubmissionData.append("entry.369072528", values.fullName);
    formSubmissionData.append("entry.1713557164", values.email);
    formSubmissionData.append("entry.117460637", values.phoneNumber);
    formSubmissionData.append("entry.376922771", values.organisation);
    formSubmissionData.append(
      "entry.545201816",
      values.listingsPerMonth.toString()
    );
    formSubmissionData.append("entry.563339746", values.platforms);
    formSubmissionData.append("entry.291291381", values.location);

    try {
      await fetch(googleFormURL, {
        method: "POST",
        mode: "no-cors",
        body: formSubmissionData,
      });
      toast({
        title: "Book Demo",
        description: "Form submitted successfully!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setSubmitResponse(true);
    } catch (error) {
      toast({
        title: "Book Demo",
        description: "Error submitting form",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Error submitting form:", error);
    }
  };
  useEffect(()=>{
    if(submitResponse){
      setTimeout(()=>{
        window.location.href='https://chromewebstore.google.com/detail/infinity-ai-by-hammoq/bemfglnkeaeomabmdoopjpbcbacpnfne'
      },11000)
    }
  },[submitResponse])

  // Adjusting modal size based on screen size (using Chakra's responsive utility)
  const modalSize = useBreakpointValue({ base: "full", md: "2xl" });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay />
      <ModalContent
        top={{ base: "6rem", lg: "3rem" }}
        zIndex={"999999"}
        minHeight={"40vh"}
        overflow={{ base: "auto", lg: "none" }}
        bgColor={"whitesmoke"}
      >
        <ModalHeader>Book A Demo</ModalHeader>
        <Box p={4}>
          <ModalCloseButton rounded={"full"} border={"1px solid"} />
          <ModalBody>
            {submitResponse ? (
              <VStack>
                <Text fontSize={"1.2rem"}>
                  Your data is submitted successfully.
                </Text>
                <Text mt={-3} fontSize={"1.75rem"} textAlign={"center"}>
                  Our team will reach out to you soon.
                </Text>
                <VStack
                  border={"1px solid"}
                  w={"100%"}
                  textAlign={"center"}
                  minH={"100px"}
                  rounded={"md"}
                  p={"1rem"}
                  mt={8}
                  position={'relative'}
                >
                  <Text fontSize={"1.2rem"} mt={{base:"1.2rem",xl:"0"}}>
                    In the meantime try, Infinity AI -
                    <Text as={"span"} fontWeight={600}>
                      it's free!
                    </Text>
                  </Text>
                  <HStack justify={"center"} w={"100%"} mt={"1rem"}>
                    <Button
                      bgColor={"#ffd400"}
                      _hover={{ bg: "#e6c200" }}
                      _active={{ bg: "#ccaa00" }}
                      className={HeroSectionStyle.blinking}
                      w={{ base: "30%", md: "30%" }}
                      rounded={"full"}
                      onClick={() => {
                        window.location.href='https://chromewebstore.google.com/detail/infinity-ai-by-hammoq/bemfglnkeaeomabmdoopjpbcbacpnfne'
                      }}
                    >
                      Try it Now
                    </Button>
                  </HStack>
                  <Box position={'absolute'} top={'0.5rem'} right={"0.5rem"}>
                  <CountdownCircleTimer
                    isPlaying
                    size={30}
                    strokeWidth={2}
                    duration={10}
                    colors={["#ff0000", "#ff0000", "#ff0000", "#ff0000"]}
                    colorsTime={[10,5 ,2, 0]}
                  >
                    {({ remainingTime }) => remainingTime}
                  </CountdownCircleTimer>
                  </Box>
                </VStack>
              </VStack>
            ) : (
              <BookDemoForm onSubmit={onSubmit} />
            )}
          </ModalBody>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default BookDemoModal;
