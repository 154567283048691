import { Box, Button, Text, useDisclosure, VStack } from "@chakra-ui/react";
import React from "react";
import BookDemoModal from "../HeroSection/components/bookDemoModal";

type Props = {};

const BookDemoComponent = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <VStack
      height={"25vh"}
      my={"0.5rem"}
      backgroundColor={"#ffd400"}
      justify={"center"}
      alignItems={"center"}
      w={"100%"}
    >
      <Text color={'#393939'} textAlign={'center'} w={{base:"90%",xl:"auto"}} paddingX={{base:"10px",xl:"auto"}} fontSize={{base:'1.5rem',xl:'2.5rem'}} my={'0.5rem'} fontFamily={'system-ui'}>
      {/* Infinity AI users say they can now create 50 listings in just 15 minutes */}
      Infinity AI users say, they can now create <Text as='span' fontWeight={600}>50</Text> listings in just <Text as='span' fontWeight={600}>15</Text> minutes
      </Text>
      <Button
        colorScheme="black"
        color={"balck"}
        variant={"outline"}
        rounded={"lg"}
        backgroundColor={"white"}
        paddingY={"1.5rem"}
        paddingX={"5rem"}
        fontWeight={{base:500,xl:500}}
        fontSize={{base:"1.5rem",xl:"1.5rem"}}
        onClick={() => {
          onOpen();
        }}
      >
        find out how !
      </Button>
      <BookDemoModal isOpen={isOpen} onClose={onClose} />
    </VStack>
  );
};

export default BookDemoComponent;
