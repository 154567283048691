// import { Box, Text } from "@chakra-ui/react";
// const InfinityAILogo = () => {
//   return (
//     <Box textAlign={"right"} padding={"1rem"} lineHeight={"1.5rem"}>
//       <Text fontSize={"1.75rem"} fontStyle={"inter"} fontWeight={"600"}>
//         Infinity AI
//       </Text>
//       <Text textAlign={"right"} fontSize={"1rem"} fontWeight={600}>
//         by Hammoq
//       </Text>
//     </Box>
//   );
// };

// export default InfinityAILogo;
import { Box, Text } from "@chakra-ui/react";

const InfinityAILogo = () => {
  return (
    <Box textAlign={"right"} padding={{ base: "0.5rem", lg: "0.5rem" }} lineHeight={"1.5rem"}>
      <Text
        fontSize={{ base: "1.5rem", lg: "1.75rem" }} // Smaller font size for mobile
        fontStyle={"inter"}
        fontWeight={"600"}
      >
        Infinity AI
      </Text>
      <Text
        textAlign={"right"}
        fontSize={{ base: "0.875rem", lg: "1rem" }} // Smaller font size for mobile
        fontWeight={600}
      >
        by Hammoq
      </Text>
    </Box>
  );
};

export default InfinityAILogo;
