import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useBreakpointValue,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FaBars } from "react-icons/fa"; // React Icon for hamburger menu
import InfinityAILogo from "../Infinity-ai-logo";
import BookDemoModal from "../../Routes/LandingPage/Components/HeroSection/components/bookDemoModal";

function Navbar({ scrollSmoothHandler }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen:bookdemoIsOpen, onOpen:bookdemoOnOpen, onClose:bookdemoOnClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false }); // Detect if it's mobile (base is for mobile)

  const handleRedirect = (path: string) => {
    window.location.href = path;
  };

  return (
    <Box
      w="100%"
      position={"sticky"}
      top={0}
      backgroundColor={"white"}
      zIndex={"9999"}
      boxShadow={'md'}
    >
      <HStack
        justify={"space-between"}
        padding={{ base: "1rem", xl: "0rem 5rem",lg:"0rem 4rem",md:"0rem 3rem",sm:"0rem 2rem" }} // Adjust padding for mobile
        w={{base:"100%",xl:"90%",'2xl':"80%"}} // Full width for mobile
        margin={"auto"}
        align={"center"}
      >
        <InfinityAILogo />
        {isMobile ? (
          // Hamburger menu for mobile view
          <>
            <IconButton
              icon={<FaBars />} // Use the React Icon here
              variant="outline"
              onClick={isOpen?onClose:onOpen}
              aria-label="Open Menu"
            />
            <Drawer isOpen={isOpen} onClose={onClose} size="full">
              <DrawerOverlay />
              <DrawerContent mt={{base:"6rem",sm:"4rem",lg:"3rem"}}>
                <DrawerCloseButton />
                <DrawerBody p={4}>
                  <VStack spacing={6} align={"flex-start"}>
                    <Text
                      cursor={"pointer"}
                      onClick={() => {
                        scrollSmoothHandler("home");
                        onClose();
                      }}
                    >
                      Home
                    </Text>
                    <Menu>
                      <MenuButton>Product</MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={() => {
                            handleRedirect("https://conv.hammoq.com/about");
                          }}
                        >
                          Our Other Products
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            scrollSmoothHandler("intelligent");
                            onClose();
                          }}
                        >
                          Infinity AI
                        </MenuItem>
                      </MenuList>
                    </Menu>
                    <Text
                      cursor={"pointer"}
                      onClick={() => {
                        scrollSmoothHandler("faq");
                        onClose();
                      }}
                    >
                      FAQs
                    </Text>
                    <Text
                      cursor={"pointer"}
                      onClick={() => {
                        scrollSmoothHandler("testimonials");
                        onClose();
                      }}
                    >
                      Testimonials
                    </Text>
                  </VStack>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </>
        ) : (
          // Desktop view with horizontal menu
          <>
          
          <HStack fontStyle={"inter"} fontWeight={500} gap={"2rem"} justify={'center'}>
            <Text
              cursor={"pointer"}
              onClick={() => {
                scrollSmoothHandler("home");
              }}
            >
              Home
            </Text>
            <Menu>
              <MenuButton>Product</MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    handleRedirect("https://conv.hammoq.com/about");
                  }}
                >
                  Our Other Products
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    scrollSmoothHandler("intelligent");
                  }}
                >
                  Infinity AI
                </MenuItem>
              </MenuList>
            </Menu>
            <Text
              cursor={"pointer"}
              onClick={() => {
                scrollSmoothHandler("faq");
              }}
            >
              FAQs
            </Text>
            <Text
              cursor={"pointer"}
              onClick={() => {
                scrollSmoothHandler("testimonials");
              }}
            >
              Testimonials
            </Text>
          </HStack>
            <Button
              colorScheme="black"
              color={"black"}
              variant={"outline"}
              rounded={"lg"}
              paddingY={"1rem"}
              onClick={() => {
                bookdemoOnOpen()
              }}
            >
              Book A Demo
            </Button></>
        )}
      </HStack>
      <BookDemoModal isOpen={bookdemoIsOpen} onClose={bookdemoOnClose} />
    </Box>
  );
}

export default Navbar;
