import {
  Box,
  Button,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { RxHamburgerMenu } from "react-icons/rx";
import LottieAnimation from "../../../../Components/LottieAnimation";
import robotGreen from "../../../../assets/animations/robotgreen.json";
import HeroSectionStyle from "./index.module.css";
import { BsArrowRight } from "react-icons/bs";
import { delay, easeOut, motion } from "framer-motion";
import FadeInLeft from "../../../../Components/letterAnimation/fadeInLeft";
import FadeUpBox from "../../../../Components/FadeUpBox";
import ThreeDText from "../../../../Components/ThreeDText";
import Infinitydashboard from "./components/infinityDashboard";
import BookDemoModal from "./components/bookDemoModal";
import { useEffect, useRef, useState } from "react";

const MotionVStack = motion(VStack);
const MotionText = motion(Text);

function HeroSection() {
  const containerVariants = {
    hidden: { opacity: 1 }, // Keeps the container visible
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.03, // Delay between each letter
        delayChildren: 0.2, // Initial delay before letters start appearing
      },
    },
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const heightRef = useRef<any>();
  const [dynamicHeight, setDynamicHeight] = useState(500);
  useEffect(() => {
    setDynamicHeight(heightRef.current.offsetHeight);
  }, []);
  return (
    <Stack
      justify={{ base: "center", md: "space-between" }} // Center on mobile, space-between on larger screens
      alignItems={{ base: "center", xl: "start" }}
      paddingX={{ base: "2rem", md: "5rem" }} // Adjust padding for mobile
      paddingTop={{ base: "2rem", md: "4rem", xl: "7rem" }} // Adjust padding for mobile
      w={{ base: "100%", xl: "90%", "2xl": "80%" }} // Full width for mobile
      margin={"auto"}
      marginBottom={{ base: "4rem", md: "9rem" }} // Adjust padding for mobile
      spacing={{ base: "2rem", md: "2rem" }} // Adjust spacing between elements
      direction={{ base: "column", xl: "row" }} // Stack items on mobile and row on desktop
      // height={{base:"auto",xl:'80vh'}}
      //["2rem", "2rem", "1.5rem", "0.5rem"]
      gap={{base:"2rem",xl:"0.5rem"}}
    >
      <VStack
        w={{ base: "100%", md: "60vw" }}
        //["auto", "auto", "auto", `${dynamicHeight}px`]
        h={{base:"auto",xl:`${dynamicHeight}px`}}
        position={"relative"}
        justify={"space-between"}
        //["center", "center", "center", "start"]
        align={{base:"center",xl:"start"}}
      >
        {" "}
        {/* Full width on mobile */}
        <VStack w={"100%"}>
          <HStack
            as={motion.div}
            flexWrap={"wrap"}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            spacing="0"
            padding={{ base: "0.5rem", lg: "0" }}
            justify={{ base: "center", xl: "left" }}
            width={"100%"}
          >
            {"Effortlessly Autofill Item Specifications with"
              .split(" ")
              .map((word: string, index: number) => {
                return (
                  <Text
                    key={index}
                    color={"#393939"}
                    fontSize={{ base: "1rem", xl: "1.5rem" }}
                    lineHeight={{ base: "2.5rem", md: "3rem", lg: "4.5rem" }}
                  >
                    <FadeInLeft text={word} />
                    &nbsp;&nbsp;&nbsp;
                  </Text>
                );
              })}
            {"Infinity AI".split(" ").map((word: string, index: number) => {
              return (
                <Text
                  key={index}
                  color={"#ffd400"}
                  lineHeight={{ base: "2.5rem", md: "3rem", lg: "4.5rem" }}
                >
                  <FadeInLeft text={word} ParentElement={ThreeDText} />
                  &nbsp;&nbsp;
                </Text>
              );
            })}
          </HStack>
          <MotionText
            fontSize={{ base: "1.25rem", md: "1.65rem" }} // Adjust font size for mobile
            fontWeight={700}
            fontStyle={"inter"}
            color={"gray.500"}
            mt={"3rem"}
            width={"100%"}
            textAlign={{ base: "center", lg: "left" }}
            initial={{opacity:0,scale:0.7,y:50}}
            animate={{opacity:1,scale:1,y:0}}
            transition={{duration:1,delay:1.5 }}
          >
            Maximize your listing efficiency by{" "}
            <Text as="span" color={"#393939"}>
              10X
            </Text>{" "}
            and reduce your time spent by{" "}
            <Text as="span" color={"#393939"}>
              50%
            </Text>{" "}
            on eBay and other marketplaces!
          </MotionText>
        </VStack>
        <HStack
          justify={{ base: "center", xl: "left" }}
          align={"end"}
          height={"100%"}
          // position={{base:"relative"}}
          // // bottom={"0rem"}
          // // height={'50vh'}
        >
          <Box mt={"3rem"} paddingTop={"0.75rem"} display={"flex"} gap={"2rem"}>
            <FadeUpBox>
              <Button
                colorScheme="black"
                color={"balck"}
                variant={"outline"}
                rounded={"lg"}
                paddingY={"1.5rem"}
                onClick={() => {
                  onOpen();
                }}
              >
                Get Started For Free
              </Button>
            </FadeUpBox>
          </Box>
        </HStack>
      </VStack>
      <Box ref={heightRef} w={{ base: "100%", lg: "50vw", xl: "40vw" }}>
        {" "}
        {/* Adjust for mobile */}
        <Infinitydashboard bookdemoOpen={onOpen} />
      </Box>
      <BookDemoModal isOpen={isOpen} onClose={onClose} />
    </Stack>
  );
}

export default HeroSection;
