import { Box } from "@chakra-ui/react";
import Navbar from "../../Components/Navbar";
import HeroSection from "./Components/HeroSection";
import FeatureSection from "./Components/FeatureSection";
import LisintgSpeedInfo from "./Components/LisitingSpeed";
import Footer from "../../Components/Footer";
import Testimonials from "./Components/Testimonials";
import Faq from "./Components/FAQ";
import { useRef } from "react";
import MultiMarketSupport from "./Components/MultiMarketSupport";
import MultiMarketContent from "./Components/MultiMarketContent";
import IntelligentAutoFill from "./Components/IntelligentAutofill";
import BookDemoComponent from "./Components/bookDemo/bookDemoComponent";

const LandingPage = () => {
  const FaqRef = useRef();
  const TestimonialsRef = useRef();
  const IntelligentRef = useRef();
  const scrollSmoothHandler = (ref: any) => {
    switch (ref) {
      case "home":
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      case "faq":
        window.scrollTo({
          top: (FaqRef.current as any)?.offsetTop - 50,
          behavior: "smooth",
        });
        return;
      case "testimonials":
        window.scrollTo({
          top: (TestimonialsRef.current as any)?.offsetTop - 50,
          behavior: "smooth",
        });
        return;
      case "intelligent":
        window.scrollTo({
          top: (IntelligentRef.current as any)?.offsetTop - 50,
          behavior: "smooth",
        });
        return;
    }
  };
  return (
    <Box position={"relative"} m={0} p={0} overflowX={'hidden'}>
      <Navbar scrollSmoothHandler={scrollSmoothHandler} />
      <HeroSection />
      <MultiMarketContent />
      <MultiMarketSupport />
      <IntelligentAutoFill IntelligentRef={IntelligentRef} />
      {/* <FeatureSection /> */}
      <LisintgSpeedInfo />
      <BookDemoComponent />
      <Faq FaqRef={FaqRef} />
      <Testimonials TestimonialsRef={TestimonialsRef} />
      <Footer />
    </Box>
  );
};

export default LandingPage;
