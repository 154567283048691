import LandingPage from "./LandingPage";
import { Route, Routes } from "react-router-dom";
import Success from "./Payments/success";
import Cancel from "./Payments/cancel";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/payment/purchase/success" element={<Success />} />
      <Route path="/payment/purchase/cancel" element={<Cancel />} />
    </Routes>
  );
};

export default AllRoutes;
