import { HStack, Text, VStack, useBreakpointValue } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionVStack = motion(VStack);
const MotionHStack = motion(HStack);

const IntelligentAutoFill = ({ IntelligentRef }: any) => {
  // Responsive values
  const videoHeight = useBreakpointValue({ base: "30vh", md: "40vh", lg: "50vh",xl:"60vh",'2xl':"70vh" });
  const vStackWidth = useBreakpointValue({ base: "100vw", md: "80vw", lg: "70vw" });
  const headingFontSize = useBreakpointValue({ base: "2rem", md: "3rem", lg: "3rem", xl: "5rem" });
  const subheadingFontSize = useBreakpointValue({ base: "1.5rem", md: "2rem", lg: "2rem" });
  const paddingX = useBreakpointValue({ base: "1rem", md: "2rem", lg: "2rem" });

  return (
    <VStack
      ref={IntelligentRef}
      w={"100vw"}
      justify={"center"}
      paddingTop={"3rem"}
      paddingBottom={"5rem"}
    >
      <MotionVStack
        w={vStackWidth}
        justify={"center"}
        spacing={"2rem"}
        padding={"1rem"}
        animate={{ scale: 0.8, opacity: 0.8 }}
        transition={{ duration: 0.5 }}
        whileInView={{ scale: 1, opacity: 1 }}
      >
        <Text
          fontStyle={"normal"}
          fontWeight={700}
          fontSize={headingFontSize}
          lineHeight={"3.6rem"}
          display={"flex"}
          alignItems={"center"}
          textAlign="center"
          color={'#393939'}
        >
          Intelligent Autofill
        </Text>
        <Text
          color={"gray.500"}
          fontWeight={600}
          fontSize={subheadingFontSize}
          w={"100%"}
          paddingX={paddingX}
          textAlign={"center"}
        >
          Infinity AI analyzes product data to automatically fill in item
          specifications, ensuring accuracy and saving you hours of manual work.
        </Text>
      </MotionVStack>

      <MotionHStack
        justify={"center"}
        border={"1rem solid #ffd400"}
        w={'fit-content'}
        rounded={'lg'}
        height={videoHeight}
        animate={{ scale: 0.8, opacity: 0.8 }}
        transition={{ duration: 0.5 }}
        whileInView={{ scale: 1, opacity: 1 }}
      >
        <video autoPlay loop muted style={{ width: "100%", height: "100%" }}>
          <source src={"/demoVideo.mp4"} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </MotionHStack>
    </VStack>
  );
};

export default IntelligentAutoFill;
