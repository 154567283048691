import { Box, Button } from "@chakra-ui/react";
import { delay, motion } from "framer-motion";

// Extend Chakra's Button with Framer Motion
const MotionBox = motion(Box);

function FadeUpBox({ children }:any) {
  return (
    <MotionBox
      initial={{ opacity: 0, y: 50 }} // Start with zero opacity and below the initial position
      animate={{ opacity: 1, y: 0 }} // Fade in and move to the original position
      transition={{ duration: 0.6, ease: "easeOut",delay:2 }} // Smooth transition
    >
      {children}
    </MotionBox>
  );
}

export default FadeUpBox;
