import { Text, Box } from "@chakra-ui/react";
import { motion } from "framer-motion";

// Wrap Text with motion for animations
const MotionText = motion(Text);

function ThreeDText({ children }:any) {
  return (
    <MotionText
    as='span'
    //   textShadow="1px 1px 0px black, 2px 2px 0px black, 3px 3px 0px black" // Layers of shadow for 3D effect
      letterSpacing="wide"
      style={{
        // WebkitTextStroke: "0.5px gray", // Adds border to each letter
      }}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      {children}
    </MotionText>
  );
}

export default ThreeDText;
