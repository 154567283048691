import { Box, Grid, GridItem, HStack, Image, Text, VStack } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box
      w={"100%"}
      minHeight={"300px"}
      bgColor={"#101010e0"}
      color={"white"}
      position={"relative"}
    >
      <HStack justify={{base:"left",md:'center'}} w={{base:"90%",lg:"80%"}}>
        <Grid gridTemplateColumns={{base:"repeat(2,1fr)",sm:"repeat(3,1fr)"}} padding={{base:"1rem",md:"2rem"}} gap={{base:"2rem",md:"5rem",lg:"10rem",xl:"15rem"}}>
          <VStack alignItems={"start"}>
            <Text fontWeight={700}>COMPANY</Text>
            <Text>Home</Text>
            <Text>Fetaures</Text>
            <Text>Listing Speed</Text>
            <Text>Testimonials</Text>
          </VStack>
           <VStack alignItems={"start"}>
            <Text fontWeight={700}>LEGAL</Text>
            <Text>Privacy Policy</Text>
            <Text>Terms & Conditions</Text>
          </VStack>
          <VStack alignItems={"start"}>
            <Text fontWeight={700}>CONNECT</Text>
            <Text>Instagram</Text>
            <Text>Facebook</Text>
            <Text>Linkedin</Text>
          </VStack>
        </Grid>
      </HStack>
      <Box padding={"1rem"}></Box>
      <HStack w={"100%"} position={"absolute"} bottom={"0px"} margin={"auto"}>
        <Text m="auto" paddingBottom={"0.5rem"}>
          &#169; 2024 Infinity AI. All rights reserved.{" "}
        </Text>
      </HStack>
    </Box>
  );
};

export default Footer;
