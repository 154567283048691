import Lottie from "lottie-react";

type Props = {
  animationData: any;
  style?: any;
};

const LottieAnimation = (props: Props) => {
  return <Lottie {...props} />;
};

export default LottieAnimation;
