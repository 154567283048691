import { Image, VStack } from "@chakra-ui/react";

const Logocard = ({ logo,height='100px',width='100px',imageWidth='100%' }: any) => {
  return (
    <VStack
      justify={"center"}
      alignItems={"center"}
      border={"1px solid"}
      borderColor={"transparent"}
      rounded={"xl"}
      boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
      width={width}
      height={height}
      padding={"1rem"}
    >
      <Image src={logo} width={imageWidth} />
    </VStack>
  );
};

export default Logocard;
