import { Box, Image, Text, VStack } from "@chakra-ui/react";
import Navbar from "../../Components/Navbar";
import cancel from "../../assets/images/close.png";

const Cancel = () => {
  return (
    <Box>
      <Navbar />
      <VStack>
        <VStack>
          <Text fontSize={"2rem"} fontWeight={800}>
            Cancel Payment.
          </Text>
          <Text>cancel subscription</Text>
        </VStack>
        <Image src={cancel} alt={"cancel image"} />
      </VStack>
    </Box>
  );
};

export default Cancel;
