import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useForm, SubmitHandler } from 'react-hook-form';

// Define the type for form values
export interface FormValues {
  fullName: string;
  email: string;
  phoneNumber: string;
  organisation: string;
  listingsPerMonth: number;
  platforms: string;
  location: string;
}

function BookDemoForm({ onSubmit, errorResponse }: any) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <Box mx="auto" p={6} boxShadow="lg" borderRadius="md" width="100%" maxWidth="800px" bgColor={'white'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Full Name and Email Fields */}
        <VStack spacing={4} align="stretch">
          <HStack spacing={4} w="100%" flexDirection={{base:"column",xl:"row"}}>
            <FormControl isInvalid={!!errors.fullName} mb={4} isRequired>
              <FormLabel>Full Name</FormLabel>
              <Input
                type="text"
                placeholder="Full Name"
                {...register('fullName', {
                  required: 'Full Name is required',
                  minLength: { value: 3, message: 'Minimum length should be 3' },
                })}
              />
              <FormErrorMessage>{errors.fullName?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.email} mb={4} isRequired>
              <FormLabel>Email ID</FormLabel>
              <Input
                type="email"
                placeholder="Email"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Invalid email format',
                  },
                })}
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
          </HStack>

          {/* Organization and Phone Fields */}
          <HStack spacing={4} w="100%" flexDirection={{base:"column",xl:"row"}}>
            <FormControl isInvalid={!!errors.organisation} mb={4} isRequired>
              <FormLabel>Organization Name</FormLabel>
              <Input
                type="text"
                placeholder="Organization Name"
                {...register('organisation', {
                  required: 'Organization name is required',
                })}
              />
              <FormErrorMessage>{errors.organisation?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.phoneNumber} mb={4} isRequired>
              <FormLabel>Phone Number</FormLabel>
              <Input
                type="tel"
                placeholder="Phone Number"
                {...register('phoneNumber', {
                  required: 'Phone number is required',
                  pattern: { value: /^[0-9]+$/, message: 'Only numbers are allowed' },
                  minLength: { value: 10, message: 'Minimum length is 10 digits' },
                  maxLength: { value: 15, message: 'Maximum length is 15 digits' },
                })}
              />
              <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
            </FormControl>
          </HStack>

          {/* Listings Per Month and Platforms Fields */}
          <HStack spacing={4} w="100%">
            <FormControl isInvalid={!!errors.listingsPerMonth} mb={4} isRequired>
              <FormLabel>Number of listings per month</FormLabel>
              <Input
                type="number"
                placeholder="Number of listings"
                {...register('listingsPerMonth', {
                  required: 'This field is required',
                  min: { value: 1, message: 'Must be at least 1' },
                })}
              />
              <FormErrorMessage>{errors.listingsPerMonth?.message}</FormErrorMessage>
            </FormControl>
          </HStack>

          {/* Platforms and Location Fields */}
          <FormControl isInvalid={!!errors.platforms} mb={4}>
            <FormLabel>Platforms you list on</FormLabel>
            <Textarea
              placeholder="List the platforms, separated by commas"
              {...register('platforms')}
            />
            <FormErrorMessage>{errors.platforms?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.location} mb={4}>
            <FormLabel>Location</FormLabel>
            <Input
              type="text"
              placeholder="Location"
              {...register('location')}
            />
            <FormErrorMessage>{errors.location?.message}</FormErrorMessage>
          </FormControl>

          {/* Submit Button */}
          <Button
            bgColor={"#ffd400"}
            _hover={{ bg: "#e6c200" }}
            _active={{ bg: "#ccaa00" }}
            type="submit"
            width="full"
          >
            Submit
          </Button>
        </VStack>
      </form>
    </Box>
  );
}

export default BookDemoForm;
